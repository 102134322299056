@font-face {
  font-family: "Helvetica Light";
  font-style: normal;
  font-weight: normal;
  src: local("Helvetica Light"), url("./helvetica-light.woff") format("woff");
}

.defaultFont {
  font-family: Helvetica;
}
html {
  background-color: #fff;
}
