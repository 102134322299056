.container {
	background-color: #fff;
}
.mainContent {
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 5em;
}

.textSection {
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.imageStyles {
	width: 30em;
	height: 15em;
}

.boldText {
	font-family: Helvetica-Bold;
	font-size: 20px;
	color: #485560;
	letter-spacing: 0;
	text-align: center;
	line-height: 30px;
}

.lightText {
	font-family: Helvetica;
	font-size: 16px;
	color: #657688;
	letter-spacing: 0;
	text-align: center;
	line-height: 30px;
}

.mainContent > button {
	padding-top: .7em;
	padding-bottom: .7em;
}
