.flexContainer {
  // TODO - refactor to remove secondary scrollbar in dashboard views
  // height: 98.5vh;
  overflow: auto;

  -webkit-transition: width 0.25s ease;
  -moz-transition: width 0.25s ease;
  -o-transition: width 0.25s ease;
  transition: width 0.25s ease;
}
