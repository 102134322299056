@import "../../../../styles/colors/common";

.container {
  display: flex;
  flex-direction: column;
  background: #fbfbfb;
  width: 100%;
  height: 100vh;
  // position: relative;

  &__inner {
    max-width: 100%;
    background-color: #fbfbfb;
    display: flex;
    flex-direction: column;
    flex: 1 1;
  }

  &__navbar {
    -webkit-box-shadow: 0px 2px 5px -1px rgba(0, 0, 0, 0.055);
    box-shadow: 0px 2px 5px -1px rgba(0, 0, 0, 0.055);
    z-index: 1;
    position: sticky;
    top: 0;
    display: flex;
    flex: 0;
    flex-direction: column;
  }

  &__footer {
    // position: absolute;
    // bottom: 0;
    display: flex;
    flex: 0;
    width: 100%;
    background-color: white;
    z-index: 1;
  }
}
