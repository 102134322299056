.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* background-color: #212324; */
  background-color: #f5f7f8;
  width: 270px;
  height: 100%;
}

.headerSection {
  display: flex;
  flex-direction: column;
  padding: 15px 0 0 0;
}

.headerContent {
  display: flex;
  align-items: center;
  height: 34px;
  font-size: 14px;
  font-weight: normal;
  /* color: #dddddd; */
  color: #627d98;
  padding: 0 20px;
  cursor: pointer;
  text-decoration: none;
}

.headerContent > a {
  color: #627d98;
}

.section {
  display: flex;
  flex-direction: column;
}

.section2 {
  display: flex;
  flex-direction: column;
  padding: 0px 0 15px 0;
}

.section:first-child {
  border-bottom: 1px solid #e3eaed;
}

.sectionTitle {
  font-size: 11px;
  font-weight: bold;
  color: #cbd6e1;
  height: 20px;
  padding: 0 20px;
}

.sectionContent > a {
  display: flex;
  align-items: center;
  height: 34px;
  font-size: 14px;
  font-weight: normal;
  /* color: #dddddd; */
  color: #627d98;
  padding: 0 20px;
  cursor: pointer;
  text-decoration: none;
}

.sectionContent > a:hover {
  /* background-color: #313435; */
  background-color: #e3eaed;
  color: #627d98;
}

.active {
  /* background-color: #313335; */
  background-color: #e3eaed;
  color: #4178d8 !important;
  font-weight: bold !important;
}

.footerSection {
  display: flex;
  flex-direction: column;
  padding: 15px 0 15px 0;
  border-bottom: 1px solid #e3eaed;
  margin-bottom: 7vh;
}

.footerContent {
  display: flex;
  align-items: center;
  height: 34px;
  font-size: 14px;
  font-weight: normal;
  color: #cbd6e1;
  padding: 0 20px;
  cursor: default;
  text-decoration: none;
}

.indented {
  padding: 0 60px !important;
}

.indentedActive {
  padding: 0 60px !important;
  background-color: #e3eaed;
  color: #4178d8 !important;
  font-weight: bold !important;
}

.activeNoBg {
  background-color: #f5f7f8;
}

.withLabel {
  display: flex;
  justify-content: space-between;
}

.linkContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.standAlonelinkContainer {
  padding-left: 20px;
  color: #627d98;
  cursor: pointer;
  height: 34px;
  display: flex;
  align-items: center;
  text-align: center;
}

.standAlonelinkContainer:hover {
  background-color: #e3eaed;
}

.linkItem {
  font-size: 14px;
  font-weight: 400;
  color: #627d98;
  cursor: pointer;
}
