/* Set pixel value to 10 as default */
html {
  font-size: 14px;
}
body {
  background-color: #fff !important;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.rw-widget-input {
  box-shadow: none !important;
}
.rw-widget-picker {
  border: none !important;
  font-size: 1.3rem;
  font-family: "Montserrat";
}
.rw-dropdown-list-input {
  padding: 0px !important;
}
