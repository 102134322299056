//responsive done
.footer {
  background-color: white;

  &__container {
    width: 80%;
    margin: 0rem auto;
    padding: 3rem 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__logo {
    width: 4.5rem;
    margin-bottom: 3rem;
  }

  &__item {
    margin: 2rem 0;
    cursor: pointer;
  }

  &__info {
    width: 100%;
    color: white;
    background-color: #3478bd;
    padding: 2rem 0;
  }

  &__infoContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  &__terms {
    width: 65%;
    padding-top: 2rem;
    margin: 0 auto;
  }

  &__left,
  &__right {
    width: 30%;
    padding: 2rem 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
  }
}

.text {
  &__header {
    font-size: 1.4rem;
    font-weight: 400;
    margin-bottom: 2rem;
  }

  &__sub {
    font-size: 1.2rem;
    line-height: 1.8;
    margin-top: 2rem;
    text-decoration: none;
    color: inherit;
  }

  &__link {
    cursor: pointer;
  }

  &__margin {
    &__sm {
      margin-bottom: 0;
      line-height: 0.1;
    }
  }
}

@media (max-width: 700px) {
  .footer {
    &__container {
      font-size: 1.2rem;
    }

    &__infoContainer {
      flex-direction: column;
    }

    &__left {
      margin-bottom: 3rem;
      border-bottom: 1px solid rgb(197, 197, 197);
    }

    &__left,
    &__right,
    &__terms {
      text-align: center;
      width: 70%;
    }
  }
}
