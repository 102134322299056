@import "../../../styles/colors/_common.scss";

.buttonDefault {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  color: #7d8d91;
  padding: 2px 5px;
  cursor: pointer;
  font-size: 16px;
  box-shadow: 0 1px 2px 0 rgba(212, 171, 171, 0.08);
  border: solid 1px #dee5e6;
  background-color: #ffffff;
}

.buttonDefault:active {
  background-color: #0064b7;
}

.buttonSecondary {
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  text-align: center;
  padding: 2px 5px;
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
  font-family: Helvetica;
  font-size: 16px;
  color: #7d8d91;
  letter-spacing: 0;
  border: 1px solid #7d8d91;
}

.buttonPrimary {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: $primaryColor;
  border-radius: 5px;
  border: 1px solid white;
  color: white;
  padding: 2px 5px;
  cursor: pointer;
  font-size: 16px;
}

.primary {
  display: flex;
  min-height: 46px;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: $primaryColor;
  border-radius: 4px;
  border: 1px solid;
  border-color: $primaryColor;
  color: white;
  padding: 0px 30px;
  margin: 5px 5px;
  cursor: pointer;
  font-size: 16px;
}
.success {
  display: flex;
  height: 46px;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 30px;
  margin: 5px 5px;
  cursor: pointer;
  background: #1ac07e;
  border-radius: 3px;
  font-family: Helvetica;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 0;
  line-height: 18px;
}
.danger {
  display: flex;
  height: 46px;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 30px;
  margin: 5px 5px;
  cursor: pointer;
  background: #c42c14;
  border-radius: 3px;
  font-family: Helvetica;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 0;
  line-height: 18px;
}
.warning {
  display: flex;
  height: 46px;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 30px;
  margin: 5px 5px;
  cursor: pointer;
  font-family: Helvetica;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 0;
  line-height: 18px;
  background: #fff1d2;
  border: 1px solid #e7ce98;
  border-radius: 3px;
  font-family: Helvetica;
  font-size: 14px;
  color: #ac761c;
  letter-spacing: 0;
  line-height: 40px;
}
.primaryRadius {
  display: flex;
  height: 46px;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: $primaryColor;
  border-radius: 20px;
  border: 1px solid;
  border-color: $primaryColor;
  color: white;
  padding: 0px 30px;
  margin: 5px 5px;
  cursor: pointer;
  font-size: 16px;
}
.secondary {
  display: flex;
  height: 46px;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  letter-spacing: normal;
  cursor: pointer;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  text-align: center;
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
  padding: 0px 30px;
  margin: 5px 5px;
  font-family: Helvetica;
  font-size: 14px;
  color: #7d8d91;
  letter-spacing: 0;
}

.transparent {
  display: flex;
  height: 46px;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #7d8d91;
  background: transparent;
  border: transparent;
  cursor: pointer;
  padding: 0px 30px;
  margin: 5px 5px;
}

.image {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.loadingImage {
  width: 30px;
  height: 30px;
  margin: 0 auto;
}

.disabled {
  border: 0px;
  background-color: #e1eaf0;
  cursor: not-allowed;
}

.fluid {
  width: 100%;
}

.lightText {
  opacity: 0.7;
  font-family: Helvetica;
  font-size: 11px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
}

.bold2 {
  font-family: Helvetica-Bold;
  font-size: 1rem;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
}

.extraPadding {
  display: flex;
  flex-direction: column;
  padding: 32px 20px;
}
.nowrap {
  white-space: nowrap;
}
