.modal {
    &__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
		padding-left: 2rem;
		font-size: 1.7rem;
    }
}

.rightSubHeader {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 45px;
	height: 45px;
	border-radius: 50%;
	cursor: pointer;
	background-position: center;
}